<!--基本html代码区域-->
<template>
  <div class="register-container">
    <div class="register-banner-size">
      <div class="banner-limit banner-info">
        <span class="banner-fMax">用户注册</span>
        <span class="banner-fMin banner-other"
        >送政策、送服务，助推企业发展</span
        >
      </div>
    </div>
    <div class="description">
      <div class="banner-limit dscn-content">
        <div class="dscn-left">注册说明：</div>
        <div class="dscn-right">
          <div v-for="(item, index) in dscnList" :key="index">{{
            item.text
            }}
          </div>
        </div>
      </div>
    </div>
    <div class="banner-limit register-public-announcement">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="企业注册" name="first"></el-tab-pane>
        <el-tab-pane label="个人注册" name="second"></el-tab-pane>
      </el-tabs>
      <div class="register-self">
        <div class="form-container flex-row-between">
          <!-- <span>请输入注册信息</span> -->
          <div class="form-left">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="120px"
              label-position="left"
              :status-icon="true"
              size="small"
              :hide-required-asterisk="false"
            >
              <div class="flex-row form-row" v-if="activeName === 'second'">
                <el-form-item label="个人昵称" prop="name">
                  <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <span class="el-input-comments"> 个性昵称 </span>
              </div>
              <div class="flex-row form-row">
                <el-form-item label="登录账户" prop="loginId">
                  <el-input v-model="form.loginId"></el-input>
                </el-form-item>
                <span class="el-input-comments"
                >请输入6~20位字符，需包含小写字母和数字</span
                >
              </div>
              <div class="flex-row form-row">
                <el-form-item label="登录密码" prop="loginPw">
                  <el-input
                    type="password"
                    v-model="form.loginPw"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <span class="el-input-comments"
                >请输入6~20位字符，需包含小写字母、大写字母、数字和特殊字符</span
                >
              </div>
              <div class="flex-row form-row">
                <el-form-item label="确认密码" prop="checkPass">
                  <el-input
                    type="password"
                    v-model="form.checkPass"
                    autocomplete="off"
                  ></el-input>
                </el-form-item>
                <span class="el-input-comments">请再次输入密码</span>
              </div>
              <div class="flex-row form-row" v-if="activeName === 'second'">
                <el-form-item label="身份证号码" prop="idcard">
                  <el-input v-model="form.idcard" autocomplete="off"></el-input>
                </el-form-item>
                <span class="el-input-comments"></span>
              </div>
              <div class="flex-row form-row">
                <el-form-item label="手机号" prop="phone">
                  <el-input v-model="form.phone" autocomplete="off"></el-input>
                </el-form-item>
                <span class="el-input-comments"
                >手机号码可用于找回密码、找回账户等</span
                >
              </div>
              <div class="flex-row form-row" v-if="activeName === 'first'">
                <el-form-item label="公司名称" prop="name">
                  <el-input v-model="form.name" autocomplete="off"></el-input>
                </el-form-item>
                <span class="el-input-comments"></span>
              </div>
              <div class="flex-row form-row" v-if="activeName === 'first'">
                <el-form-item label="社会信用代码" prop="shxydm">
                  <el-input v-model="form.shxydm" autocomplete="off"></el-input>
                </el-form-item>
                <span class="el-input-comments"></span>
              </div>
              <div class="flex-row form-row" v-if="activeName === 'second'">
                <el-radio-group v-model="iskjs">
                  <el-radio :label="false">个人用户</el-radio>
                  <el-radio :label="true">会计师</el-radio>
                </el-radio-group>
              </div>
            </el-form>
            <div class="el-btn-ctr">
              <el-button @click.native.prevent="submitForm('form')" type="primary"
              >注 册
              </el-button
              >
            </div>
            <div class="to-login">
              <a @click="gotoLogin">已有账号，直接登录</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<!--数据存贮交互，事件控制地区-->
<script>
  export default {
    name: "register",
    data() {
      /* 自定义验证规则 */
      let validateUser = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入账号"));
        } else {
          const reg = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z]).*$/;
          console.log(reg.test(value));
          if (reg.test(value)) {
            if (this.form.checkPass !== "") {
              this.$refs.form.validateField("checkPass");
            }
            callback();
          } else {
            return callback(new Error("最少6位，包含小写字母，数字"));
          }
        }
      };

      let validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          const reg = /^.*(?=.{6,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[~\\`!@{}|\[\];:'"<>,./?#$%\^&*?().+=\-_]).*$/;
          // const reg = /^.*(?=.{6,})(?=.*\d)(?=.*[a-z]).*$/;
          console.log(reg.test(value));
          if (reg.test(value)) {
            if (this.form.checkPass !== "") {
              this.$refs.form.validateField("checkPass");
            }
            callback();
          } else {
            return callback(
              // new Error("最少6位，包含大写字母，小写字母，数字，特殊字符")
              new Error("最少6位，包含小写字母，数字，特殊字符")
            );
          }
        }
      };
      let validatePass2 = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请再次输入密码"));
        } else if (value !== this.form.loginPw) {
          callback(new Error("两次输入密码不一致!"));
        } else {
          callback();
        }
      };
      let checkPhone = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("手机号不能为空"));
        } else {
          const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的手机号"));
          }
        }
      };
      let checkShxydm = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("社会信用代码不能为空"));
        } else {
          const reg = /^([0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}|[1-9]\d{14})$/;
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的社会信用代码"));
          }
        }
      };
      let checkIdcard = (rule, value, callback) => {
        if (!value) {
          return callback(new Error("证件号码不能为空"));
        } else {
          const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
          console.log(reg.test(value));
          if (reg.test(value)) {
            callback();
          } else {
            return callback(new Error("请输入正确的证件号码"));
          }
        }
      };
      return {
        // /* 注册类型切换 */
        // registerType: 'first',
        iskjs: false, //是否为会计师  0表示为会计师
        // 默认选择
        activeName: "first",
        /* 地址选择 */
        baseUrl: {
          first: "/dev-api/regist_enterprise", // 企业
          second: "/dev-api/regist_personal", // 个人
        },
        /* 注册列表 */
        form: {
          loginId: "chengzjjljle",
          loginPw: "Cz.##555",
          checkPass: "Cz.##555",
          idcard: "430623588545258665",
          name: "",
          phone: "18587876597",
          shxydm: "91310000MA1G8B8AXD",
        },
        /* 注册说明 */
        dscnList: [
          {
            text: "1、注册个人账户只能办理个人事项，注册法人账号只能办理法人事项",
          },
          {
            text:
              "2、注册账号时，需要通过手机接收短信验证，请正确填写您的手机号码",
          },
          {text: "3、较多业务系统将使用填写的注册信息，请如实填写"},
          {text: "4、请牢记注册的登录账号和密码，切勿向他人泄漏"},
        ],
        /* 验证规则 */
        rules: {
          loginId: [
            {required: true, validator: validateUser, trigger: "blur"},
            // { min: 6, message: "长度大于 6 个字符", trigger: "blur" },
          ],
          loginPw: [{required: true, validator: validatePass, trigger: "blur"}],
          checkPass: [
            {required: true, validator: validatePass2, trigger: "blur"},
          ],
          name: [{required: true, message: "请完整输入", trigger: "blur"}],
          phone: [{required: true, validator: checkPhone, trigger: "blur"}],
          shxydm: [{required: true, validator: checkShxydm, trigger: "blur"}],
          idcard: [{required: true, validator: checkIdcard, trigger: "blur"}],
        },
      };
    },
    methods: {
      /* 注册 */
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let params = this.form;
            params.kjs =0
            if (this.iskjs) {
              params.kjs = 1;
            }
            const el = this.activeName; // 当前状态
            this.$httpApi
              .post(
                this.baseUrl[el] +
                "?appid=afc903d9d5e449488a3cf9a00c3d1294&appkey=8cb4f61b-e89a-405b-acc8-4d992826c247",
                params
              )
              .then((res) => {
                console.log("注册后", res);
                if (res.code == 200) {
                  this.$message({
                    message: "恭喜你，注册成功",
                    type: "success",
                  });
                  this.$router.replace({
                    path: "/login",
                    query: {
                      user: this.form.loginId,
                    },
                  });
                } else if (res.code == 500) {
                  this.$message.error(res.msg || "填写信息错误！");
                }
              })
              .catch((err) => {
                this.$message.error("网络异常，请稍后重试!");
              });
          } else {
            console.log("error submit!!");
            this.$message.closeAll();
            this.$message.error("请填写正确的用户信息!");
            return false;
          }
        });
      },
      gotoLogin() {
        this.$router.replace({
          path: "/login",
        });
      },
      /**
       * 切换
       */
      handleClick(tab, event) {

      },
    },
  };
</script>

<!-- 写样式的地方 -->
<style lang="scss">
  .register-container {
    // height: 762px;
  }

  /* 横幅 */
  .register-banner-size {
    width: 100%;
    height: 150px;
    background: url("../../assets/images/icon_banner_declare.png");

    .banner-info {
      height: 100%;
      display: flex;
      align-items: center;
      color: #fff;

      .banner-fMax {
        font-size: 40px;
      }

      .banner-other {
        margin-left: 80px;
        font-size: 20px;
      }
    }
  }

  /* 注册说明 */
  .description {
    width: 100%;
    background-color: #fafafa;

    .dscn-content {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      padding: 30px 0;
      margin-bottom: 30px;

      .dscn-left {
        height: 21px;
        font-weight: 700;
        color: #404040;
        font-size: 16px;
        text-align: left;
        white-space: nowrap;
      }

      .dscn-right {
        display: flex;
        flex-direction: column;
        // flex-flow: wrap;
        height: 49px;
        color: #404040;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 10px;
      }
    }
  }

  /*  注册的 tabs */
  .register-public-announcement {
    // height: 850px;
    // background: red;
    .el-tabs {
      .el-tabs__header {
        // background: #333;
        .el-tabs__nav {
          height: 70px;
        }

        .el-tabs__item {
          height: 26px;
          font-weight: 700;
          // color:#2d81ff;
          font-size: 20px;
          line-height: 70px;
        }

        .el-tabs__active-bar {
          width: 81px;
          height: 5px;
          background-color: #2d81ff;
        }
      }
    }
  }

  /* 注册排版 */
  .register-self {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    /* 表单布局 */
    .form-container {
      width: 100%;
      height: 100%;

      .form-left {
        width: 100%;
        height: 100%;

        span {
          font-size: 16px;
          font-weight: 600;
        }

        /* element UI 表单样式 */
        .el-form {
          width: 100%;
          padding: 10px 40px 0 40px;
          // .el-form-item {
          //   border: 1px solid #333;
          // }
          .form-row {
            // border:1px solid red;
            margin-bottom: 14px;
          }

          .el-form-item {
            // width: 100%;
            width: 550px;
            height: 40px;
            border: 1px solid #d6d6d6;
            background: #fff;
            /* label */
            .el-form-item__label {
              height: 38px;
              line-height: 38px;
              padding-left: 10px;
            }

            /* input框 */
            .el-form-item__content {
              // width: 100%;
              // background: red;
              .el-input {
                width: 420px;
                height: 28px;
                line-height: 38px;

                .el-input__inner {
                  width: 100%;
                  height: 100%;
                  border: none;
                  border-radius: 0;
                  border-left: 1px solid #d6d6d6;
                }
              }
            }
          }

          /* 每行输入框后边的注释 */
          .el-input-comments {
            width: 400px;
            height: 40px;
            // line-height: 40px;
            font-weight: 400;
            color: #828282;
            font-size: 14px;
            text-align: left;
            margin-left: 30px;
            margin-bottom: 20px;
          }
        }

        .el-btn-ctr,
        .to-login {
          width: 30%;
          padding: 0 40px;
          margin: 20px auto;
        }

        .el-button {
          width: 100%;
        }

        .to-login {
          width: 30%;
          margin-top: 10px;
          text-align: center;
        }
      }

      .form-right {
        width: 160px;
        height: 100%;
        background: yellow;
      }
    }
  }
</style>
